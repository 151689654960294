import React from 'react';
import { UX2, constants } from '@wsb/guac-widget-core';
import { cartSetup } from '@pnc/gopay-cart-setup-util';
import dataAids from '../../constants/dataAids';
import { ICON_SIZE } from '../../../../../constants';
import { getGoPayCartFF, getCountStyles, handleCartRedirect } from './utils';
import { CartIconGoPayPropTypes } from './ComponentPropTypes';

/**
 * NOTE: this component duplicates some functionality in `Component.js`.
 * This is a temporary solution until OLS has been integrated with the new GoPay cart.
 * The main reason for this file is that `Component.js` imports necessary functions from
 * `@wsb/guac-widget-ols-core` and when the SHOP widget is not present on a published site,
 * OLSCore will be undefined and so the code in `Component.js` will break. This component (GoPayCartIcon)
 * handles the case where a site only has OLA but not OLS. Once the GoPay Cart has been released for both
 * OLS and OLA, we can safely remove this file and rely 100% on `Component.js`.
 */

class GoPayCartIcon extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      olaQuantity: 0,
      olsQuantity: 0,
      olaGopayCartOn: false
    };

    this.dropdownToggleId = `cart-dropdown-${+new Date()}`;
  }

  componentDidMount() {
    if (this.hasOlaWidget) {
      this.setupOlaCart();
    }
  }

  componentWillUnmount() {
    if (this.enabledForOla && this.gopayCart && this.gopayCartUnsubscribe) {
      this.gopayCartUnsubscribe();
    }
  }

  setupOlaCart = async () => {
    const { websiteId, rootDomain, env, renderMode } = this.props;

    if (this.gopayCart) return;

    try {
      // Read cart FF and exit if it's off
      const shouldUseCache = !!window.sessionStorage;
      const isGopayCartOn = await getGoPayCartFF({ shouldUseCache, websiteId, rootDomain });
      if (!isGopayCartOn) return;

      // Activate cart icon for OLA
      this.setState({ olaGopayCartOn: true });

      if (renderMode !== constants.renderModes.PUBLISH) {
        // Don't load cart into editor
        return;
      }

      // Load gopay cart and subscribe to updates
      this.gopayCart = await cartSetup({ websiteId, env });
      this.updateOlaStoreState();
      this.gopayCartUnsubscribe = this.gopayCart.subscribe(this.updateOlaStoreState);
      handleCartRedirect({ cart: this.gopayCart, renderMode });
    } catch (ex) {
      this.gopayCart = null;
      this.setState({ olaGopayCartOn: false });
    }
  };

  updateOlaStoreState = () => {
    if (!this.gopayCart) return;

    const { quantitiesByType } = this.gopayCart.getState();
    const olaQuantity = quantitiesByType.service || 0;
    this.setState({ olaQuantity });
  };

  get hasOlaWidget() {
    return !!this.props.appointmentsPageId;
  }

  get enabledForOla() {
    return this.hasOlaWidget && this.state.olaGopayCartOn;
  }

  get isPublishMode() {
    return this.props.renderMode === constants.renderModes.PUBLISH;
  }

  get shouldRender() {
    return this.enabledForOla;
  }

  get totalCartQuantity() {
    const { olsQuantity, olaQuantity } = this.state;

    return olsQuantity + olaQuantity;
  }

  renderItemCount = () => {
    const countStyles = getCountStyles(this.props.category);

    if (this.totalCartQuantity === 0) {
      return null;
    }

    return (
      <UX2.Element.Block
        style={{ ...countStyles.wrapper, marginLeft: '!-6px' }}
        data-aid={ dataAids.CART_ICON_COUNT }
      >
        <UX2.Element.Details.Minor style={{ ...countStyles.count, color: 'inherit' }} featured>
          { this.totalCartQuantity }
        </UX2.Element.Details.Minor>
      </UX2.Element.Block>
    );
  };

  getLinkProps = () => {
    // We don't want to trigger cart to show in editor
    if (!this.isPublishMode) {
      return {};
    }

    return {
      // Note: `this.gopayCart` is not available until cart is loaded
      onClick: () => this.gopayCart.toggle()
    };
  };

  renderIcon() {
    const { cartStyles } = this.props;

    return (
      <UX2.Element.Icon
        icon='cart1'
        style={ cartStyles }
        data-aid={ dataAids.CART_ICON_RENDER }
        size={ ICON_SIZE }
        minTarget={ true }
      />
    );
  }

  cartRouter = () => {
    const { staticContent = {} } = this.props;
    const styles = {
      link: {
        display: 'flex',
        alignItems: 'center',
        cursor: this.isPublishMode ? 'pointer' : 'not-allowed'
      }
    };

    return (
      <UX2.Element.Link
        style={ styles.link }
        aria-label={ staticContent.cartIcon || 'Shopping Cart Icon' }
        { ...this.getLinkProps() }
      >
        { this.renderIcon() }
        { this.renderItemCount() }
      </UX2.Element.Link>
    );
  };

  render() {
    if (!this.shouldRender) return null;

    const cartStyles = {
      display: 'flex',
      alignItems: 'center',
      fontSize: 'xsmall'
    };

    return <UX2.Element.Block style={ cartStyles } children={ this.cartRouter() } />;
  }
}

GoPayCartIcon.propTypes = CartIconGoPayPropTypes;

export default GoPayCartIcon;
